#root {
    width: 100vw;
    height: 100vh;
    padding: 0px 0px;
}

.full-width-height {
    width: 100%;
    height: 100%;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

