.img-container {
    width: 100%;
    height: 170px;
}

.title {
    font-size: 28px;
    font-weight: 700;
}

.tenant-title {
    color: indianRed;
    font-size: 24px;
    font-weight: 800;
}

.tenant-id {
    color: grey;
    font-size: 22px;
    font-weight: normal;
}

.swap-tenant {
    color: #FFFFFF;
    background: #1890FF;
    width: 18px;
    height: 18px;
    margin-left: 12px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

.swap-tenant:hover {
    opacity: 0.6;
}

.choose-process {
    width: 50%;
    height: 50px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid #CDCDCD;
    background: #EEEEEE;
}

.choose-process:hover {
    background: #FAFAFA;
}

.installation-type {
    font-size: 22px;
}

.wait-loading-icon {
    font-size: 24px;
    color: #777777;
}

.wait-loading-text {
    font-size: 20px;
}

.get-production-ipc-error-text {
    font-size: 16px;
    color: indianRed;
}

.production-ipc-select {
    width: 70%;
    margin-bottom: 12px;
    text-align: center;
}

.ipc-input {
    width: 70%;
    text-align: center;
}

.submit-btn {
    width: 70%;
    margin-top: 12px;
}

.current-tenant {
    background: orange;
    color: white;
    border-radius: 3px;
    width: 60px;
}

.other-tenant {
    background: lightgrey;
    color: white;
    border-radius: 3px;
    width: 60px;
    cursor: pointer;
}

.other-tenant:hover {
    opacity: 0.6;
}

.start-process-text {
    font-size: 20px;
}

.start-process-icon {
    font-size: 32px;
    margin-top: 36px;
    color: #140619;
}

.error-process-icon {
    font-size: 32px;
    margin-top: 36px;
    color: #FF4D4F;
}

.success-icon {
    font-size: 48px;
}

.success-text {
    font-size: 22px;
}

.fail-icon {
    font-size: 48px;
}

.fail-text {
    font-size: 22px;
}

.params-error {
    font-size: 14px;
}

.img-container, .choose-process, .current-tenant, .other-tenant {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

