.alert-icon {
    animation: alertBlink steps(5, start) 0.4s infinite;
    color: rgb(230, 18, 32);
}

@keyframes alertBlink {
    80% {
        color: rgb(0, 0, 0);
    }
}

